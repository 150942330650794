import React from 'react';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import { StaticImage } from "gatsby-plugin-image";

const Footer = props => {
  return (
    <footer>
    <div id="r-container">
      <StaticImage
            src="../static/images/rl.png"
            alt="Rodolfo Galván logo"
            placeholder="blurred"
            layout="constrained"
            width={400}
            height={430}
          />
    </div>
    <div id="contact">
      <div className="c-1">
        <a id="github" href="https://github.com/galvanitic" target="_blank" rel="noreferrer"><GitHubIcon></GitHubIcon></a>
        <a id="linkedin" href="https://www.linkedin.com/in/rjgalvan/" target="_blank" rel="noreferrer"><LinkedInIcon></LinkedInIcon></a>
        <a id="twitter" href="https://twitter.com/galvanitic" target="_blank" rel="noreferrer"><TwitterIcon></TwitterIcon></a>
      </div>
      <div className="c-2">
        <a id="instagram" href="https://www.instagram.com/galvanitic/" target="_blank" rel="noreferrer"><InstagramIcon></InstagramIcon></a>
        <a id="facebook" href="https://www.facebook.com/galvanitic/" target="_blank" rel="noreferrer"><FacebookIcon></FacebookIcon></a>
        <a href="mailto:hola@rglvn.com"><EmailIcon></EmailIcon></a>
      </div>
    </div>
    <p id='cr'>
      Rodolfo J. Galván Martínez © 2022
    </p>
  </footer>
  );
};

export default Footer;