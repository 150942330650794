import React, { PropTypes } from 'react';
import BookIcon from '@material-ui/icons/Book';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage'; 
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Navigation = props => {
  return (
    <div>
      <nav>
      <div className="name-container">
        <Link to="/">
          <StaticImage
              src="../static/images/rl.png"
              alt="Rodolfo Galván logo"
              placeholder="blurred"
              layout="constrained"
              width={40}
              height={43}
            />
        </Link>
      </div>
      <div className="items">
        {/* <BookIcon />
        <EmojiFoodBeverageIcon /> */}
      </div>
    </nav>
    </div>
  );
};

Navigation.propTypes = {
  
};

export default Navigation;